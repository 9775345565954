<template>
  <el-table
      :data="tableData"
      style="width: 100%">
    <el-table-column
        prop="id"
        label="保函编号">
    </el-table-column>
    <el-table-column
        prop="returnsUnit"
        label="受益单位"
        width="180">
    </el-table-column>
    <el-table-column
        prop="tenderingUnit"
        label="投标单位">
    </el-table-column>
    <el-table-column
        prop="projectName"
        label="项目名称">
    </el-table-column>
    <el-table-column
        prop="theyPromis"
        label="承诺金额">
    </el-table-column>
    <el-table-column
        prop="timeLimit"
        label="包含日期">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "Documents",
  data(){
    return{
      tableData:[],
    }
  },
  methods:{

  },
  created(){

  }


}
</script>

<style scoped>

</style>