<template>
  <div>
    <div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="保函编号" prop="id">
          <el-input v-model="ruleForm.id"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">查询</el-button>
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
      <el-dialog
          title="保函信息"
          :visible.sync="centerDialogVisible"
          width="100%"
          center>
        <div style="margin-left: 150px">
          保函编号：{{ this.document.id }}<br/>
          受益人 :{{ this.document.returnsUnit }}<br/>
          被保险人：{{ this.document.tenderingUnit }}<br/>
          项目名称：{{ this.document.projectName }}<br/>
          保函金额：{{ this.document.theyPromis }}元<br/>
          保函期限：{{ this.document.timeLimit }}<br>
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
  </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "search",
  data() {
    return {
      rules: {
        id: [
          {required: true, message: '请输入包含编号', trigger: 'blur'},
        ],
      },
      ruleForm: {
        id: null,
      },
      centerDialogVisible: false,
      document: {
        id: null,
        returnsUnit: null,
        tenderingUnit: null,
        projectName: null,
        theyPromis: null,
        timeLimit: null,
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.axios.get(this.$location + "documents/getDocumentById?id=" + this.ruleForm.id).then(res => {
            if (res.data.result) {
              this.document = res.data.data;
              this.centerDialogVisible = true;
            } else {
              this.$message.error("未查询到相应的保函信息");
            }
          })
        } else {
          this.$message.error("请输入要查询的保函编号")
          return false;
        }
      });

    },
    resetForm() {
      this.ruleForm.id = "";
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>